/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/2/22
 */
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
import { Menu } from "antd"
import { setKV, setKnowledgeQuestionTypes, setRelationTypes, setOrgList, setQuestionType, setQuestionRelationTypes, setPromptTpl, setExercises, setArea } from "actions/base"
import styles from "./Header.module.less"
import helper from "utils/helper"
import api from "api/constants"
import apiOrg from "api/organizations"
import apiPromptTpl from "api/promptTemplate"
import { setConstants, setExerciseType } from "../actions/base"
import useShallowEqualSelector from "utils/useShallowEqualSelector"
// import apiZone from 'api/zones'

function Header(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { models } = useShallowEqualSelector(({ base }) => ({
        models: base.constants.models,
    }))
    const [subjectName, setSubjectName] = useState("")
    const [me, setMe] = useState({})
    const handleClick = useCallback(() => {
        history.push("/dashboard")
    }, [])
    const handleDataClick = useCallback(() => {
        history.push("/stats/students")
    }, [])

    const initData = useCallback(async () => {
        if (models.length) {
            console.log("已初始化！") // 避免重复请求
            return
        }
        const _KV = {}
        const res = await api.knowledgeQuestionType()
        if (res?.data) {
            dispatch(setKnowledgeQuestionTypes(res.data))
        }
        const res1 = await api.relationTypes()
        if (res1?.data) {
            dispatch(setRelationTypes(res1.data))
        }
        const questionType = await api.questionTypes()
        if (questionType?.data) {
            dispatch(setQuestionType(questionType.data))
        }
        const orgs = await apiOrg.getList({ limit: 1000 })
        if (orgs?.data) {
            dispatch(setOrgList(orgs.data))
        }
        const rel = await api.getQuestionRelationTypes({ limit: 1000 })
        if (rel?.data) {
            dispatch(setQuestionRelationTypes(rel.data))
        }
        const tpl = await apiPromptTpl.getPromptTemplates({ limit: 1000 })
        if (tpl?.data) {
            dispatch(setPromptTpl(tpl.data))
        }
        const listenStatuses = await api.listenStatuses()
        const area = await api.areas({ limit: 1000 })
        if (area?.data) {
            dispatch(setArea(area.data))
            _KV["area"] = helper.arrToKV(area.data)
        }
        const diffLevels = await api.diffLevels()
        const topics = await api.topics()
        const genres = await api.genres()
        const standards = await api.standards()
        const paperTypes = await api.paperTypes()
        const versionTypes = await api.versionTypes() //用户版本类型
        const exerciseModes = await api.exerciseModes() //题库模式列表
        const questionHandleStatuses = await api.questionHandleStatuses()
        const exerciseTypes = await api.getExerciseTypes({ limit: 1000 })
        if (exerciseTypes?.data) {
            dispatch(setExerciseType(helper.arrToOptions(exerciseTypes.data || []), false))
        }
        const data = {
            topics: helper.arrToOptions(topics?.data || []), // 主题语境
            genres: helper.arrToOptions(genres?.data || []), //语篇列表
            diffLevels: helper.arrToOptions(diffLevels?.data || []), // 难度等级
            standards: helper.arrToOptions(standards?.data || []), // 难度等级
        }
        _KV["listenStatuses"] = helper.arrToKV(listenStatuses?.data || []) // 题目听力处理状态列表
        _KV["topics"] = helper.arrToKV(topics?.data || []) // 主题语境
        _KV["genres"] = helper.arrToKV(genres?.data || []) // 主题语境
        _KV["diffLevels"] = helper.arrToKV(diffLevels?.data || []) // 主题语境
        _KV["standards"] = helper.arrToKV(standards?.data || []) // 高中试题标准
        _KV["exerciseTypes"] = helper.arrToKV(exerciseTypes?.data || []) // 高中试题标准
        _KV["paperTypes"] = helper.arrToKV(paperTypes?.data || []) // 高中试题标准
        _KV["versionTypes"] = helper.arrToKV(versionTypes?.data || []) // 用户版本类型
        _KV["questionHandleStatuses"] = helper.arrToKV(questionHandleStatuses?.data || []) // 高中试题标准
        _KV["exerciseModes"] = helper.arrToKV(exerciseModes?.data || []) // 高中试题标准
        dispatch(setExercises(data))
        dispatch(setKV(_KV))
        const _models = await api.models({ limit: 1000 })
        if (_models?.data) {
            let _data = _models.data
            if (window.localStorage.getItem("gptUrl") && window.localStorage.getItem("gptApiKey")) {
                _data = _models.data.concat({ id: "custom", name: "自定义" })
            }
            dispatch(setConstants({ models: helper.arrToOptions(_data) }))
        }
        // 试卷类型
        dispatch(setConstants({ exerciseModes: helper.arrToOptions(exerciseModes?.data || []) }))
        dispatch(setConstants({ listenStatuses: helper.arrToOptions(listenStatuses?.data || []) }))
        dispatch(setConstants({ paperTypes: helper.arrToOptions(paperTypes?.data || []) }))
        dispatch(setConstants({ versionTypes: helper.arrToOptions(versionTypes?.data || []) }))
        dispatch(setConstants({ questionHandleStatuses: helper.arrToOptions(questionHandleStatuses?.data || []) }))
        // const zones = await apiZone.getList({ limit: 10000 })
        // if (zones?.data) {
        //     dispatch(setZoneList(zones.data))
        // }
        // const _me = await apiAdmin.getMe()
        // if (_me.data) {
        //     setMe(_me.data)
        // }

        // const skill = await api.skillType()
        // if (skill.data) {
        //     dispatch(setSkillTypes(skill.data))
        // }
    }, [dispatch])

    useEffect(() => {
        const subjectID = window.localStorage.getItem("subject_id")
        if (!subjectID) {
            if (history.location.pathname !== "/dashboard") {
                history.push("/dashboard")
            }
            window.localStorage.setItem("subject_id", "3")
        } else {
            if (subjectID) {
                const subjects = JSON.parse(window.localStorage.getItem("subjects") || "[]")
                for (let i = 0; i < subjects.length; i++) {
                    if (subjects[i].id === +subjectID) {
                        setSubjectName(subjects[i].name)
                    }
                }
            }
        }
        if (history.location.search) {
            const params = helper.parseParams(history.location.search)
            if (params["subject_id"] && +params["subject_id"] !== +subjectID) {
                window.localStorage.setItem("subject_id", params["subject_id"])
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            }
        }
        initData()
    }, [])

    return (
        <div className="header">
            <div className={styles.header}>
                <div className={styles.logo}>
                    <span>番茄卡片</span>
                </div>
                <Menu theme="dark" mode="horizontal">
                    <Menu.Item key="1" onClick={handleDataClick}>
                        数据中心
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleClick}>
                        选择科目
                    </Menu.Item>
                </Menu>
                <div className={styles.subjectName}>
                    {me.username ? `账号：${me.username}` : ""}&nbsp;&nbsp;&nbsp;&nbsp;
                    {subjectName ? `当前科目：${subjectName}` : ""}
                </div>
            </div>
        </div>
    )
}

export default Header
